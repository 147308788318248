import { Subscription } from "@/ecommerce/subscriptions/base-subscription";
import { getGotmVariants } from "@/ecommerce/config";
import type {
  RechargeSubscription,
  // SubscriptionStatuses,
} from "@/recharge/types";
import { ConfigProductType } from "@/ecommerce/config/types";
import type { SubscriptionConfig } from "@/ecommerce/subscriptions/types";
import { GotmPrefix } from "@/utils/tags";

const GotmSubscriptionConfig: SubscriptionConfig = {
  identifier: ConfigProductType.GOTM,
  async extractSubscriptions(customerSubscriptions: RechargeSubscription[]) {
    const variants = await getGotmVariants();
    return customerSubscriptions.filter((subscription) =>
      // subscription.status === SubscriptionStatuses.ACTIVE &&
      variants.includes(subscription.shopify_variant_id),
    );
  },
  extractTags(tags) {
    return tags.filter((tag) => tag.startsWith(GotmPrefix));
  },
};

export class GotmSubscription extends Subscription {
  static config = GotmSubscriptionConfig;
}
