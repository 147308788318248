import { getRotmVariants } from "@/ecommerce/config";
import type {
  RechargeSubscription,
  // SubscriptionStatuses,
} from "@/recharge/types";
import { ConfigProductType } from "@/ecommerce/config/types";
import { RotmPrefix } from "@/utils/tags";
import { Subscription } from "./base-subscription";
import type { SubscriptionConfig } from "./types";

const RotmSubscriptionConfig: SubscriptionConfig = {
  identifier: ConfigProductType.ROTM,
  async extractSubscriptions(customerSubscriptions: RechargeSubscription[]) {
    const variants = await getRotmVariants();
    return customerSubscriptions.filter((subscription) =>
      // subscription.status === SubscriptionStatuses.ACTIVE &&
      variants.includes(subscription.shopify_variant_id),
    );
  },
  extractTags(tags) {
    return tags.filter((tag) => tag.startsWith(RotmPrefix));
  },
};

export class RotmSubscription extends Subscription {
  static config = RotmSubscriptionConfig;
}
