export const MemberPriceTagPrefix = "member-price:";
export const LoyaltyTierTagPrefix = "loyalty-tier-";

export const ThirstClassPrefix = "subs-thirstclass";
export const ThirstClassTillPrefix = `${ThirstClassPrefix}-till`;

export const GotmPrefix = "subs-gotm";

export const RotmPrefix = "subs-rotm";

export const SubscriptionsPrefix = "subscriptions-";

export const FrequencyPrefix = "frequency-";

export enum Tags {
  Hidden = "hidden",
  Author = "author",
  Public = "public",
  MembersOnly = "members_only",
  No24Delivery = "no-24-delivery",
  Presale = "presale",
  FreeDelivery = "free-delivery",
  ActiveSubscriber = "Active Subscriber",
  ThirstClassActive = "subs-thirstclass-active",
  ThirstClassInactive = "subs-thirstclass-inactive",
  ThirstClassExpired = "subs-thirstclass-expired",
  ThirstClassCancelled = "subs-thirstclass-cancelled",
  MemberStatusTrialist = "member-status-trialist",
  MemberStatusActive3Less = "member-status-active-3less",
  MemberStatusActive3Plus = "member-status-active-3plus",
  BlackCardHolder = "black-card-holder",
}

export function getMemberPriceTag(tags: string[]): string | undefined {
  return tags.find((tag) => tag.startsWith(MemberPriceTagPrefix));
}

export function isHiddenBlogTag(tag: string): boolean {
  return tag.startsWith(`${Tags.Hidden}:`) || tag.startsWith(`${Tags.Author}:`);
}

export function excludeBlogHiddenTags(tags: string[]): string[] {
  return tags.filter((tag) => !isHiddenBlogTag(tag));
}
export enum BlogTags {
  Featured = "Featured Story",
}
