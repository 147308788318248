export const DISCOUNTS_API_ROOT =
  "https://nr-api.craftginclub.co.uk/public/discounts/info";

export interface Discount {
  discount_type: "percentage" | "fixed";
  value: number;
  duration_usage_limit: number;
}

export interface PromoDiscountsResponse {
  discounts: Discount[];
}

/**
 * Fetches promotional discounts based on a promo code.
 * @param code - The promotional code.
 * @returns A promise that resolves to a list of promotional discounts that correspond to the provided code.
 */
export async function getPromoDiscounts(
  code: string,
): Promise<PromoDiscountsResponse> {
  const response = await fetch(`${DISCOUNTS_API_ROOT}?code=${code}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return (await response.json()) as PromoDiscountsResponse;
}

export interface OrderInfo {
  boxTitle: string;
  etd?: string;
  handle: string;
}
export const ORDER_INFO_API_ROOT =
  "https://nr-api.craftginclub.co.uk/public/subscription/box/info/";

/**
 * Fetches and returns the order info from the API for the provided orderId.
 *
 * @param orderId - An identifier of the order for which info needs to be fetched.
 *
 * @returns Promise resolving to an instance of OrderInfo encapsulating all details about the order.
 *
 * @throws Will throw an error if the fetch operation fails (e.g., due to network issues).
 */
export async function getOrderInfo(orderId: string): Promise<OrderInfo> {
  const response = await fetch(`${ORDER_INFO_API_ROOT}${orderId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return (await response.json()) as OrderInfo;
}

const SURVEY_API_ROOT =
  "https://hook.integromat.com/ce7f920vanlwsnnm29fvj04cu3v6v8v8";

export interface SaveSurveyPayload {
  answer: string;
  orderId?: string;
  productType?: string;
}

/**
 * Saves a survey result with the given answer, orderId, and productType.
 *
 * @param payload - The payload object.
 * @param answer - The answer for the survey.
 * @param orderId - The orderId (optional).
 * @param productType - The productType (optional).
 * @returns A promise that resolves to true if the survey result is saved successfully, false otherwise.
 *
 * @example
 * ```typescript
 * saveSurveyResult({answer: 'Yes', orderId: '123456', productType: 'Electronics'})
 *  .then(isSaved => console.log(`Survey result saved: ${isSaved}`));
 * ```
 */
export async function saveSurveyResult({
  answer,
  orderId,
  productType,
}: SaveSurveyPayload): Promise<boolean> {
  const payload = {
    answer,
    ...(orderId ? { order_id: orderId } : null),
    ...(productType ? { product_type: productType } : null),
  };
  const response = await fetch(SURVEY_API_ROOT, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.status === 200;
}

const OMETRIA_DEFAULT_FORM_ID = "92c67cbc4ae7f26f61f8033e8804d126";
const OMETRIA_API_ROOT =
  "https://nr-api.craftginclub.co.uk/public/marketing/opt-in";

export interface OmetriaNewsletterSubscribePayload {
  collection?: string;
  list: string;
  email: string;
  firstName?: string;
  lastName?: string;
  accepts?: boolean;
}

/**
 * Sends a newsletter subscribe request to the Ometria API.
 *
 * The function communicates with the Ometria API, attempting to subscribe a user to a newsletter.
 * The user's preferences such as their first name, last name, and email, as well as the collection
 * and the list they should be subscribed to, are sent in the body of the request.
 *
 * @param collection - The collection ID to which the user should be subscribed. Defaults to `OMETRIA_DEFAULT_FORM_ID` if not supplied.
 * @param list - The newsletter list to which the user should be subscribed.
 * @param email - The email of the user who should be subscribed.
 * @param firstName - The first name of the user. Optional.
 * @param lastName - The last name of the user. Optional.
 * @param accepts - Flag indicating whether the user accepts the newsletter or not. Optional.
 *
 * @returns A promise that resolves to a boolean value indicating whether the subscription was successful or not.
 */
export async function ometriaNewsletterSubscribe({
  collection,
  list,
  email,
  firstName,
  lastName,
  accepts,
}: OmetriaNewsletterSubscribePayload): Promise<boolean> {
  const body = {
    collection: collection ?? OMETRIA_DEFAULT_FORM_ID,
    add_to_lists: list,
    email,
    ...(firstName ? { first_name: firstName } : null),
    ...(lastName ? { last_name: lastName } : null),
    ...(accepts !== undefined ? { accepts } : null),
  };
  const response = await fetch(OMETRIA_API_ROOT, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response.status === 200;
}
