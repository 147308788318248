import { recharge } from "./client";
import type { RechargeAddress, RechargeCustomer } from "./types";

interface CustomerAddressesResponse {
  addresses: RechargeAddress[];
}

export async function getCustomerAddresses(
  customerId: number,
): Promise<RechargeAddress[]> {
  return (
    await recharge.get<CustomerAddressesResponse>(
      `/customers/${customerId}/addresses`,
    )
  ).addresses;
}

interface CustomerResponse {
  customer: RechargeCustomer;
}
export async function getCustomer(
  customerId: number,
): Promise<RechargeCustomer> {
  return (await recharge.get<CustomerResponse>(`/customers/${customerId}`))
    .customer;
}
