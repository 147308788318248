import { recharge } from "@/recharge/client";
import type {
  ChargeStatus,
  RechargeCharge,
  ChargesSortBy,
} from "@/recharge/types";

interface ChargesArgs {
  limit?: number;
  sortBy?: ChargesSortBy;
  status?: ChargeStatus;
  customerId?: number;
  subscriptionId?: number;
}

interface RechargeChargesResponse {
  charges: RechargeCharge[];
}

export async function getCharges({
  limit = 50,
  sortBy = "updated_at-desc",
  status,
  customerId,
  subscriptionId,
}: ChargesArgs = {}): Promise<RechargeCharge[]> {
  return (
    await recharge.get<RechargeChargesResponse>("charges", {
      limit,
      sort_by: sortBy,
      ...(status ? { status: status.toLowerCase() } : null),
      ...(customerId ? { customer_id: customerId } : null),
      ...(subscriptionId ? { subscription_id: subscriptionId } : null),
    })
  ).charges;
}
