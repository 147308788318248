import type { RechargeCharge, RechargeSubscription } from "@/recharge/types";
import { ChargeStatuses } from "@/recharge/types";
import type { SubscriptionConfig } from "@/ecommerce/subscriptions/types";
import { getCharges } from "@/recharge/charges";
import type { SubscriptionManager } from "@/ecommerce/subscription-manager";

export class Subscription {
  static config: SubscriptionConfig;
  subscription: RechargeSubscription;
  protected manager: SubscriptionManager;

  constructor(
    subscription: RechargeSubscription,
    manager: SubscriptionManager,
  ) {
    this.subscription = subscription;
    this.manager = manager;
  }

  async getCharges(
    statuses: ChargeStatuses[] = [ChargeStatuses.Success],
  ): Promise<RechargeCharge[]> {
    return getCharges({
      status: statuses.join(","),
      subscriptionId: this.subscription.id,
    });
  }
}
