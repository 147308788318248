import { GotmSubscription } from "@/ecommerce/subscriptions/gotm-subscription";
import { RotmSubscription } from "@/ecommerce/subscriptions/rotm-subscription";
import { ThirstClassSubscription } from "@/ecommerce/subscriptions/thirst-class-subscription";
import type { CustomerModel } from "@/ecommerce/customer";
import { getSubscriptions } from "@/recharge/subscriptions";
import type { RechargeSubscription } from "@/recharge/types";

type AvailableSubscriptions =
  | GotmSubscription
  | RotmSubscription
  | ThirstClassSubscription;

type SubscriptionClasses = [
  typeof GotmSubscription,
  typeof RotmSubscription,
  typeof ThirstClassSubscription,
];

export type SubscriptionManagerSubscriptions = Record<
  string,
  AvailableSubscriptions[]
>;

export class SubscriptionManager {
  customer: CustomerModel;
  readonly subscriptionClasses: SubscriptionClasses = [
    GotmSubscription,
    RotmSubscription,
    ThirstClassSubscription,
  ];

  subscriptions: SubscriptionManagerSubscriptions = {};

  constructor(customer: CustomerModel) {
    this.customer = customer;
  }

  public async load(): Promise<void> {
    const customerSubscriptions = await getSubscriptions({
      customerId: this.customer.rechargeCustomerId,
    });

    this.subscriptions = (
      await Promise.all(
        this.subscriptionClasses.map(
          async (
            SubscriptionClass,
          ): Promise<Record<string, AvailableSubscriptions[]>> => {
            const subscriptions = (
              await SubscriptionClass.config.extractSubscriptions(
                customerSubscriptions,
              )
            ).map(
              (item: RechargeSubscription) => new SubscriptionClass(item, this),
            );
            return { [SubscriptionClass.config.identifier]: subscriptions };
          },
        ),
      )
    ).reduce((res, item) => ({ ...res, ...item }), {});
  }

  getBoxSubscriptions(): (GotmSubscription | RotmSubscription)[] {
    return [
      ...this.subscriptions[GotmSubscription.config.identifier],
      ...this.subscriptions[RotmSubscription.config.identifier],
    ];
  }

  getCustomerTags(): string[] {
    return this.subscriptionClasses.reduce(
      (res, subscriptionClass) => [
        ...res,
        ...subscriptionClass.config.extractTags(this.customer.tags),
      ],
      [] as string[],
    );
  }

  async verifyThirstClassSubscription(): Promise<void> {
    const subscription =
      this.subscriptions[ThirstClassSubscription.config.identifier].at(0);
    if (subscription) {
      await (subscription as ThirstClassSubscription).verify();
    }
  }
}
