import React from "react";
import NextLink, { LinkProps } from "next/link";
import dynamic from "next/dynamic";
import { isSignUpLink } from "@/components/Link/utils";

const LinkWithDiscountCode = dynamic(
  () => import("@/components/Link/LinkWithDiscountCode"),
);

const Link: React.FC<LinkProps & React.HTMLProps<HTMLAnchorElement>> = (
  props: LinkProps,
) => {
  return isSignUpLink(props.href) ? (
    <LinkWithDiscountCode {...props} />
  ) : (
    <NextLink {...props} />
  );
};

export default Link;
