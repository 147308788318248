type Params = Record<string, unknown>;

interface RequestOptions {
  body?: string;
  method: "POST" | "GET";
  params?: null | Params;
  headers?: Record<string, string>;
}

export class Recharge {
  root = "https://api.rechargeapps.com";

  private headers(): Record<string, string> {
    return {
      "X-Recharge-Version": process.env.RECHARGE_API_VERSION ?? "2021-01",
      "X-Recharge-Access-Token": process.env.RECHARGE_API_ACCESS_TOKEN ?? "",
      "Content-Type": "application/json",
    };
  }

  makeUrl(url: string, params?: Params | null): string {
    const url_ = new URL(url, this.root);
    if (Object.entries(params ?? {}).length > 0) {
      const existedParams = Array.from(url_.searchParams.entries()).reduce(
        (res, [key, value]) => ({ ...res, [key]: value }),
        {},
      );
      const searchParams = new URLSearchParams({
        ...existedParams,
        ...params,
      });
      return new URL(
        `${url_.origin}${url_.pathname}?${searchParams.toString()}`,
      ).toString();
    }
    return url_.toString();
  }

  private async request<ResponseT>(
    url: string,
    { params, ...options }: RequestOptions,
  ): Promise<ResponseT> {
    const response = await fetch(this.makeUrl(url, params), {
      ...options,
      headers: {
        ...options.headers,
        ...this.headers(),
      },
      cache: "no-store",
    });
    // TODO: add error handling
    return (await response.json()) as ResponseT;
  }

  post<ResponseT, PayloadT>(
    url: string,
    payload: PayloadT,
    options = {},
  ): Promise<ResponseT> {
    return this.request<ResponseT>(url, {
      ...options,
      method: "POST",
      body: JSON.stringify(payload),
    });
  }

  get<ResponseT>(url: string, params?: Params): Promise<ResponseT> {
    return this.request<ResponseT>(url, {
      method: "GET",
      params,
    });
  }
}

export const recharge = new Recharge();
