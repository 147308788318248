import type { EcommerceConfig } from "@/ecommerce/config/types";

const ECOMMERCE_CONFIG_ENDPOINT =
  process.env.ECOMMERCE_CONFIG_ENDPOINT ??
  "https://us-central1-cgc-shopify.cloudfunctions.net/config";

interface EcommerceConfigResponse {
  config: EcommerceConfig;
}

export async function fetchConfig(): Promise<EcommerceConfig> {
  const response = await fetch(ECOMMERCE_CONFIG_ENDPOINT, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return ((await response.json()) as EcommerceConfigResponse).config;
}
