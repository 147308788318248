import { ConfigProductType } from "./types";
import type { ConfigProduct, EcommerceConfig } from "./types";
import { fetchConfig } from "./api";

let config: EcommerceConfig | undefined;

export async function getConfig(): Promise<EcommerceConfig> {
  if (!config) {
    config = await fetchConfig();
  }
  return config;
}

export async function getProducts(): Promise<ConfigProduct[]> {
  return Object.values((await getConfig()).products);
}

async function variantsByType(type: ConfigProductType): Promise<number[]> {
  return (await getProducts())
    .filter((product) => product.group === type)
    .map((product) => product.variantId);
}

export function getGotmVariants(): Promise<number[]> {
  return variantsByType(ConfigProductType.GOTM);
}

export function getRotmVariants(): Promise<number[]> {
  return variantsByType(ConfigProductType.ROTM);
}
