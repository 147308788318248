import { format } from "date-fns";
import type {
  RechargeAddress,
  RechargeCharge,
  RechargeSubscription,
} from "@/recharge/types";
import { ChargeStatuses, SubscriptionStatuses } from "@/recharge/types";
import { Tags, ThirstClassPrefix, ThirstClassTillPrefix } from "@/utils/tags";
import { recharge } from "@/recharge/client";
import { getCustomerAddresses } from "@/recharge/customers";
import { Subscription } from "./base-subscription";
import type { BoxSubscription, SubscriptionConfig } from "./types";

const ThirstClassSubscriptionConfig: SubscriptionConfig = {
  identifier: "thirstclass",
  // eslint-disable-next-line @typescript-eslint/require-await -- extractSubscriptions must be promise
  async extractSubscriptions(customerSubscriptions: RechargeSubscription[]) {
    return customerSubscriptions.filter(
      (subs) =>
        subs.shopify_product_id ===
        Number(process.env.ECOMMERCE_THIRSTCLASS_PRODUCT_ID),
    );
  },
  extractTags(tags) {
    return tags.filter((tag) => tag.startsWith(ThirstClassPrefix));
  },
};

export class ThirstClassSubscription extends Subscription {
  static readonly config = ThirstClassSubscriptionConfig;
  tags: string[] = [];
  _isActive: boolean | undefined;
  _expired = false;
  _cancelled = false;

  static tillTag(date: Date): string {
    return `${ThirstClassTillPrefix}-${format(date, "yyyy-MM-dd")}`;
  }

  static validateAddresses({
    addresses,
    boxSubscriptions,
  }: {
    addresses: RechargeAddress[];
    boxSubscriptions: RechargeSubscription[];
  }): boolean {
    return !addresses.some((address) => {
      const postcode = address.zip.toLowerCase();
      return boxSubscriptions.some((sub) => {
        return (
          address.id === sub.address_id &&
          (postcode.startsWith("je") || postcode.startsWith("gy"))
        );
      });
    });
  }

  public static async cancel(
    subscriptionId: number,
    cancelReason: string,
  ): Promise<void> {
    await recharge.post(`/subscriptions/${subscriptionId}/cancel`, {
      cancellation_reason: `Thirst Class ${cancelReason}`,
    });
  }

  async getCharges(): Promise<RechargeCharge[]> {
    return super.getCharges([
      ChargeStatuses.Success,
      ChargeStatuses.PartiallyRefunded,
    ]);
  }

  _nextChargeDate: Date | null | undefined;
  async nextChargeDate(): Promise<Date | null> {
    if (this._nextChargeDate !== undefined) {
      return this._nextChargeDate;
    }
    const latest = (await this.getCharges()).at(0);
    if (latest) {
      this._nextChargeDate = new Date(latest.processed_at);
      this._nextChargeDate.setFullYear(this._nextChargeDate.getFullYear() + 1);
    } else {
      this._nextChargeDate = null;
    }

    return this._nextChargeDate;
  }

  get hasActiveBoxSubscription(): boolean {
    return this.manager.getBoxSubscriptions().length > 0;
  }

  async isActive(): Promise<boolean> {
    if (this._isActive !== undefined) {
      return this._isActive;
    }
    const nextChargeDate = await this.nextChargeDate();
    this._isActive = nextChargeDate ? nextChargeDate > new Date() : false;
    return this._isActive;
  }

  async verify(): Promise<void> {
    let cancelReason: string | undefined;
    const isActive = await this.isActive();

    if (isActive) {
      if (!this.hasActiveBoxSubscription) {
        // if subscription has next charge day but doesn't have active box subscriptions
        cancelReason = "no active GOTM";
        this._isActive = false;
      } else {
        // validate addresses from box subscriptions
        const invalidAddress = ThirstClassSubscription.validateAddresses({
          addresses: await getCustomerAddresses(this.subscription.customer_id),
          boxSubscriptions: this.manager
            .getBoxSubscriptions()
            .map((item: BoxSubscription) => item.subscription),
        });
        if (invalidAddress) {
          cancelReason = "invalid GOTM address";
          this._isActive = false;
        }
      }
    } else {
      cancelReason = "expired"; // if subs doesn't have next charge date or this date is lover then today - expired
    }

    this._expired = cancelReason === "expired";

    if (
      cancelReason &&
      this.subscription.status === SubscriptionStatuses.ACTIVE
    ) {
      // cancel subscription if it's not canceled yet
      await ThirstClassSubscription.cancel(this.subscription.id, cancelReason);
      this._cancelled = true;
    }
  }

  getCustomerTags(): string[] {
    return [
      this._isActive ? Tags.ThirstClassActive : Tags.ThirstClassInactive,
      ...(this._expired ? [Tags.ThirstClassExpired] : []),
      ...(this._cancelled ? [Tags.ThirstClassCancelled] : []),
    ];
  }
}
