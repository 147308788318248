import { recharge } from "./client";
import type { RechargeSubscription, SubscriptionStatuses } from "./types";

interface SubscriptionsArgs {
  customerId?: number | string;
  status?: SubscriptionStatuses;
  shopifyVariantId?: string;
  limit?: number;
}

interface SubscriptionsResponse {
  subscriptions: RechargeSubscription[];
}
export async function getSubscriptions({
  customerId,
  status,
  shopifyVariantId,
  limit = 50,
}: SubscriptionsArgs = {}): Promise<RechargeSubscription[]> {
  return (
    await recharge.get<SubscriptionsResponse>("subscriptions", {
      limit,
      ...(status ? { status } : null),
      ...(customerId ? { customer_id: customerId } : null),
      ...(shopifyVariantId ? { shopify_variant_id: shopifyVariantId } : null),
    })
  ).subscriptions;
}
